import React, { useEffect } from "react"
import "../../../styles/course.scss"
import CourseNav from "../../../components/course/Nav"
import Footer from "../../../components/course/sections/Footer"
import SEO from "../../../components/seo"
import Section from "../../../components/course/Section"
import styled from "styled-components"

const ShopOrderIsReady = () => {
  useEffect(() => {
    const reportConversion = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const cartId = urlParams.get("cartId")

      const response = await fetch(
        "https://npd-shop.cloud.mikolajczuk.in/shop-api/cart/" + cartId
      )
      const cart = await response.json()
      if (null === localStorage.getItem("purchaseLogged")) {
        // Conversion event is delivered by server (on payment)
        // // @ts-ignore
        // if (typeof fbq !== "undefined") {
        //   // @ts-ignore
        //   fbq("track", "Purchase", {
        //     currency: "PLN",
        //     value: cart.total_amount,
        //     content_category: "LP_sell",
        //     content_name: `${cart.items[0].code}`,
        //   })
        // }
        // @ts-ignore
        if (typeof ga !== "undefined") {
          // @ts-ignore
          ga("send", {
            hitType: "event",
            eventCategory: "Zakupy",
            eventAction: "Nowe zamówienie",
            eventLabel: `${cart.items[0].name}`,
            eventValue: cart.total_amount,
            transport: "beacon",
          })
        }
        // @ts-ignore
        if (typeof gtag !== "undefined") {
          // @ts-ignore
          gtag("event", "conversion", {
            send_to: "AW-831626251/AftLCNau43UQi7jGjAM",
          })
        }
        localStorage.setItem("purchaseLogged", "true")
      }
    }

    reportConversion()
    return () => {}
  }, [])

  return (
    <>
      <SEO
        title={
          "Szkoła Rodzenia z Anną Nowak-Dudą | Bez wychodzenia z domu, ponad 700 zadowolonych Mam"
        }
        description={
          "Kompletna, profesjonalna szkoła rodzenia online. Bez stresu, dojazdów i problemów. Wsparcie także po porodzie."
        }
      />
      <CourseNav />
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop">
            <div className="container">
              <Section>
                <h2>Twoje zamówienie jest właśnie realizowane.</h2>
                <Paragraph>
                  Już za chwilę powinnaś mieć na swojej skrzynce email wiadomość
                  z danymi logowania do aplikacji kursowej.
                </Paragraph>
                <Paragraph>
                  Jeśli po kilku minutach nadal nie masz obiecanego maila -
                  sprawdź katalog SPAM w swojej skrzynce pocztowej.{" "}
                </Paragraph>
                <Paragraph>
                  W przypadku jakichkolwiek problemów - napisz lub zadzwoń do
                  nas!
                </Paragraph>
              </Section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ShopOrderIsReady

const Paragraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`
