import React, { useState } from "react"
import Section from "./Section"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const NavbarItem = styled.div`
  line-height: 1em;
`

const NavbarLink = styled.a`
  line-height: 1em;
`

const BrandContainer = styled(NavbarItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const LogoText = styled.span`
  margin-right: 5px;
  font-size: 20px;
  font-weight: 700;
`

const LogoTextSmall = styled.span`
  font-size: 0.7em;
  color: #797291;
`

const CourseNav = () => {
  const [menuActive, setMenuActive] = useState(false)
  const { logo } = useStaticQuery(query)

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <NavbarItem className="navbar-item">
          <img
            loading={"lazy"}
            style={{ alignSelf: "center", maxHeight: 50 }}
            src={logo.childImageSharp.fluid.src}
          />
        </NavbarItem>
        <BrandContainer className="navbar-item">
          <div>
            <LogoText className="has-text-font-comfortaa has-text-black">
              Szkoła
            </LogoText>
            <LogoText className="has-text-font-comfortaa has-text-primary">
              Rodzenia
            </LogoText>
          </div>
          <div>
            <LogoTextSmall>Anny Nowak-Dudy</LogoTextSmall>
          </div>
        </BrandContainer>

        <a
          role="button"
          className={`navbar-burger burger ${menuActive && "is-active"}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          onClick={() => setMenuActive(!menuActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbar"
        className={`navbar-menu ${menuActive ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <NavbarLink
            className="navbar-item"
            href="/szkola-rodzenia-z-anna-nowak-duda/#modules"
          >
            Program kursu
          </NavbarLink>

          <NavbarLink
            className="navbar-item"
            href="/szkola-rodzenia-z-anna-nowak-duda/#recomendations"
          >
            Rekomendacje
          </NavbarLink>
          <NavbarLink
            className="navbar-item"
            href="/szkola-rodzenia-z-anna-nowak-duda/#faq"
          >
            Częste pytania
          </NavbarLink>
          <NavbarLink
            className="navbar-item"
            href="/szkola-rodzenia-z-anna-nowak-duda/#about-me"
          >
            Moja historia
          </NavbarLink>
          <NavbarLink
            className="navbar-item"
            href="/szkola-rodzenia-z-anna-nowak-duda/#variants"
          >
            Zapisz Się!
          </NavbarLink>
          <NavbarLink
            className="navbar-item"
            href="/szkola-rodzenia-z-anna-nowak-duda/wspieramy-nastoletnie-mamy"
          >
            Wspieramy Nastoletnie Mamy
          </NavbarLink>
        </div>
      </div>
    </nav>
  )
}

export default CourseNav

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
