import React from "react"
import styled from "styled-components"

const Footer = () => {
  return (
    <>
      <hr />
      <footer>
        <div className="container is-widescreen">
          <div className="columns is-vcentered">
            <div className="column is-6-desktop is-offset-3-desktop is-12-mobile has-text-centered">
              <p>Skontaktuj się!</p>
              <p>
                <a
                  className="has-text-primary"
                  href="mailto:kontakt@naszepierwszedziecko.pl"
                >
                  kontakt@naszepierwszedziecko.pl
                </a>
              </p>
              <p>tel: +48 698 438 635</p>
              <hr />
              <p>Kurs opracowała i nagrała Anna Nowak-Duda.</p>
              Wydawcą kursu jest{" "}
              <span className="has-text-primary">Nasze Pierwsze Dziecko</span>.
              <p>
                <a href="https://s3.eu-central-1.amazonaws.com/pl.naszepierwszedziecko.publisher/regulamin-sklepu-15.10.pdf">
                  Regulamin sklepu
                </a>
              </p>
              <Logo
                alt="Nasze Pierwsze Dziecko - logo"
                src="/logo/logo-250-80.png"
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

const Logo = styled.img`
  max-height: 60px;
  margin-top: 20px;
`

export default Footer
